  .gauge {
    font-family: Arial, Helvetica, sans-serif;
    background: #e51616;
    /* background: #6EF; */
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.09), 0 0 35px 5px rgba(255, 255, 255, 0.29);
    width: 30vw;
    height: 15vw;
    max-width: 600px;
    max-height: 300px;
    border-radius: 15vw 15vw 0 0;
    position: relative;
    /* overflow: hidden; */
    /* safari fix */
    -webkit-transform-style: flat;
    -webkit-transform: translateZ(0px);
  }
  .gauge.min-scaled {
    transform: scale(0.5);
  }
  
  .gauge-center {
    color: #fff;
    width: 90%;
    height: 90%;
    background: hsl(209, 37%, 13%);
    border-radius: 15vw 15vw 0 0;
    position: absolute;
    box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
    right: 5%;
    bottom: 0;
    font-weight: bold;
    font-size: 25px;
    display: "flex";
    justify-content: "center";
    align-items: "center";
  }
  
  .info-area {
    color: #fff;
    width: 70%;
    height: 70%;
    background: #15222E;
    border-radius: 15vw 15vw 0 0;
    position: absolute;
    right: 15%;
    bottom: 0;
    font-weight: bold;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    z-index: 10;

  }
  
  .needle-parent{
    height: 30vw;
    width: 30vw;
    top:-15px;
    max-width: 600px;
    max-height: 600px;
    transform: rotate(0deg);
    position: relative;
    animation: speed 5s  !important;
   
  }
  .needle {
    width: 40%;
    height: 15px;
    background: #fff;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 100%;
    border-top-right-radius: 10px;
    position: absolute;
    top:calc(50% - 15px);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
   
  
  }
  

  
  
  @keyframes speed {
    20% {
      transform: rotate(0);
      top:0;
    }
    40% {
      transform: rotate(180deg);
      top:-15px;
    }
    55% {
      transform: rotate(170deg) ;;

    }
    75% {
      transform: rotate(180deg);
      top:-15px;
    }
  }

@media screen and (max-width:600px) {
  .gauge{
    width:80vw;
    height:40vw;
    border-radius:40vw 40vw 0 0;
  }
  .gauge-center{
    width: 92%;
    height: 92%;
    border-radius:40vw 40vw 0 0;
    right: 4%;
  }
  .needle-parent{
    width:80vw;
    height:80vw;
  }
  .needle{
    width: 100px;
  }

}