/* added logo */
*{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family:'ethnocentric-italic' ;
  src: url('assets/fonts/ethnocentric\ rg\ it.otf');
}
@font-face {
  font-family:'ethnocentric' ;
  src: url('assets/fonts/ethnocentric\ rg.otf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
#phone-input{
  color:"#fff"
}

.MuiTableCell-root{
 border-left:0px solid #293143 !important;
 border-right: 0px solid #293143 !important;
 border-bottom: 1px solid #293143 !important;
}

.logo-text{
  font-family: 'Roboto';
}

.logo-text::first-letter{
  color: red !important;
}

.category-table-row th{
  color: #fff;
}

.sticky-col :nth-child(3),.sticky-col-category{
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 1;
  background: #293143;
  
}
.sticky-col-public tr:nth-child(2),.sticky-col-category{
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 1;
  background: #293143;
  
}

.all-coin-table th, .all-coin-table td{
  padding: 2px 8px !important;
}

@media screen and (min-width:1740px) {
.category-table{
  display: flex;
  justify-content: center;
  margin: 10px;
}
}



.custom-chart-area  >  path{
  fill: none;
  stroke: #fff;
  stroke-width: 1px; 
  stroke-linejoin: round;
}

.x{
  font-weight: bold;

  color: red;
  font-family: 'Roboto';
}
.trade-mark{
  font-size: 12px;
  margin-left: 2px;
}

.cutome-head{
 text-align: center !important;
 width: max-content;
}

@media screen and (max-width: 600px) {
  .apexcharts-xaxis-label {
    display: none;
  }
}