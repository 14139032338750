.custom-chart-area{
    height:calc(80vh - 100px);
    width: 100%;  /* Vertical Slider spacing*/
    min-width: 300px;
    padding: 10px;
    display: flex;
    justify-content: left;
   
}

.chart-wrapper{
  
    width: 300px;
    margin-left: 20px;
    /* background: black; */
    /* overflow: hidden; */
    /* margin: 5px; */
    /* box-shadow: 0px 0px 5px #fff; */
   
}
.box{
    position: relative;
    width: 100%;
}


.price_box{
    position: relative;
    width: 100%;
}


.chart-wrapper .price_box{
    height: 100%;
    width: 20px;
    left: 20px;
    border: 1px solid #1c411e;
    background: #1c4120;
}

.chart-wrapper .box:nth-child(1){
    /* height: 14.7%; */
    height: 14.4934334%;
    /* border: 1px solid #411C32; */
    background: #411C32;
}
.chart-wrapper .box:nth-child(2){
    height: 23.4521576%;
    /* border: 1px solid #311D4100; */
    background: #311D41;
}
.chart-wrapper .box:nth-child(3){
    height: 23.4521576%;
    /* border: 1px solid #421E2B; */
    background: #421E2B;
}
.chart-wrapper .box:nth-child(4){
    height: 14.4934334%;
    /* border: 1px solid #192750; */
    background: #192750;
}
.chart-wrapper .box:nth-child(6){
    height: 5.018761726%;
    /* border: 1px solid #292C37; */
    background: #292C37 ;
}
.chart-wrapper .box:nth-child(7){
    height: 3.939962477%;
    /* border: 1px solid #292C37; */
    background: #24374D;
}
.chart-wrapper .box:nth-child(8){
    height: 2.767354597%;
    /* border: 1px solid #292C37; */
    background: #113235 ;
}
.chart-wrapper .box:nth-child(9){
    height: 3.424015009%;
    /* border: 1px solid #292C37; */
    background: #1E362C;
}
.chart-wrapper .box:nth-child(10){
    height: 3.424015009%;
    /* border: 1px solid #292C37; */
    background: #2A3A36;
}
.chart-wrapper .box:nth-child(11){
    height: 5.534709193%;
    /* border: 1px solid #292C37; */
}
.chart-wrapper .box:nth-child(12){
    height: 10px;
    bottom: 0px;
    background: green;
    /* border: 1px solid #292C37; */
}

.lable-area{
    min-width: 5px;
    width: max-content;
    display: flex;
    align-items: center;
    color: #e8d9d9;
    position: absolute;
    top:0px;
    
    
}
.lable-area .line{
    width: 200px;
    height: 1px ;
    color: #DA1F5E;
    background: #930303;
    position: absolute;
    top: 0px;;
}

.lable-areaspan{
    width: 200px;
   position:absolute;
    text-align: center;
}
.indicator{
    width: max-content;
    align-items: center;
    display: flex;
    
    color: white;
    position: absolute;
    left: 205px;    /* Current Price Arrow*/
    font-size: 12px;
}

/* .indicator div{
   visibility: hidden;
} */


.ave-buy-indicator{
    width: max-content;
    align-items: center;
    display: flex;
    color: white;
    position: absolute;
    left: 205px;    /* AveBuy Indicator*/
    font-size: 12px;
    
}

/* .plot-line{
   position: absolute;
   height: calc(6.16% );
   width: 2px;
   background: #fff;
   top: calc(100% - 38%);

} */

.fa-triangle:before {
    content: "\f2ec";
}

@media screen and (max-width:750px) {
    .custom-chart-area{
        height:80vh ;
        justify-content: left;
        margin-left: -25px;
    }
    
}