.gauge-container {
    display: flex;
    align-items: stretch;
    position: relative;
    width: 100%;
    background-color: #f0f0f0; /* Just for visibility, you can remove this */
    height: 240px;
}

.image-container {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    height: 240px;
    position: relative;
}

.year-label {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: bold;
}

.needle-4year {
    position: absolute;
    bottom: 0;
    height: 10px;
    width: 2px;
    background-color: white;
    z-index: 10;
}

.date-label {
    position: absolute;
    bottom: 10px;
    width: 100px;
    text-align: center;
    color:#fff;
    font-size: 12px;
    font-weight: bold;
    /* text-shadow: 1px 1px 2px #fff; */
}


/* ... [rest of the existing styles] ... */

.red-needle {
    position: absolute;
    top: 0;
    width: 4px;  /* double the existing needle's width */
    height: 10px;
    background-color: red;
    z-index: 10;  /* to make sure it's above other elements */
}

@keyframes pulse {
    0% {
        transform: scaleX(1);
        opacity: 1;
    }
    50% {
        transform: scaleX(1.1);
        opacity: 0.7;
    }
    100% {
        transform: scaleX(1);
        opacity: 1;
    }
}
