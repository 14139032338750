*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


tr,td{
  height: 30px !important;
  padding: 2px 14px !important;
  margin: 0px;
}

body > iframe {
  display: none;
}

.green{
  /* color:rgb(36, 136, 34); */
  color: #7B9476
}

.red{
  /* color:rgb(187, 68, 68); */
 color:  #B4343B;
}